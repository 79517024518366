.App {
    text-align: center;
    background-color: #3A3153;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(8px + 2vmin);
    color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-button {
    background-color: #3A3153;
    font: inherit;
    color: white;
    border: 2px solid white;
    padding: 8px 14px;
    border-radius: 14px;
    margin: 10px;
}

    .App-button:hover {
        background-color: white;
        color: #3A3153;
        cursor: pointer;
    }

.App-header-center {
    background-color: #3A3153;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 10px;
}

.App-header-top {
    background-color: #3A3153;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: calc(8px + 2vmin);
    color: white;
}

.App-item {
    background-color: #3A3153;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 2vmin);
    color: white;
    display: inline-block;
}

.App-item-small {
    background-color: #3A3153;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: white;
    display: inline-block;
}

input[type=text] {
    height: calc(16px + 2vmin);
    padding: 6px;
    box-sizing: border-box;
    border: 2px solid white;
    outline: none;
}

input[type=text], textarea {
    color: white;
    background-color: #3A3153;
    font: inherit;
    font-size: calc(6px + 2vmin);
}

.App-link {
  color: #61dafb;
}

select {
    border: 2px solid white;
    box-sizing: border-box;
    padding: 6px;
    font-family: inherit;
    cursor: inherit;
    line-height: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
